.fullscreen {
    min-height: max(500px, 100vh);
    padding: 60px;
    display: flex;
    flex-direction: column;
    /* justify-content: stretch; */
    color: var(--color-gray);
    background-color: var(--color-white);
}


@media screen and (max-width: 800px) {
    .fullscreen {
        padding: 30px;
    }
}


@media screen and (max-width: 600px) {
    .fullscreen {
        padding: 15px;
    }
}
:root {
    --color-white: rgba(255, 255, 255, 1);
    --color-gray: rgb(42, 42, 42);
    --color-gray-only-light: rgb(255, 255, 255);
    --color-blue: rgb(0, 81, 255);
    --color-pink: rgb(255, 1, 192);
    --color-medium-gray: #8f8f8f;
    --color-light-gray: rgb(242, 242, 242);
}

.dark {
    --color-white: rgb(23, 23, 23);
    --color-gray: rgb(255, 255, 255);
    --color-gray-only-light: rgb(255, 251, 251);
    --color-blue: rgb(255, 1, 192);
    --color-pink: blue;
    --color-medium-gray: #a0a0a0;
    --color-light-gray: rgb(41, 41, 41);
}

body {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: normal;
    background-color: var(--color-white);
}


a:link {
    text-decoration: none;
    color: inherit;
}

a:active {
    color: inherit
}

a:visited {
    color: inherit
}

h1,
h2,
h3 {
    text-transform: uppercase;
    font-weight: 900;
}


.parent {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.gallery {
    background-color: var(--color-white);
    z-index: 6;
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.card {
    width: 300px;
    flex-grow: 1;
    max-width: 400px;
}

.cardHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.cardBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    justify-content: flex-start;
    padding: 20px 0;
}

.cardBody h2,
.cardBody h3 {
    padding: 5px;
    background: var(--color-gray);
    color: var(--color-white);
}

.card h2 {
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
    box-shadow: inset 0 0 0 0 var(--color-blue);
}

.card:hover h2 {
    box-shadow: inset 200px 0 0 0 var(--color-blue);
    color: var(--color-gray-only-light);
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    text-transform: lowercase;
}

.links {
    composes: tags;
    visibility: hidden;
    align-self: center;
}

.links .tag {
    background-color: transparent;
    border: 1px solid var(--color-gray);
}

.links .tag:hover {
    background-color: var(--color-gray);
    color: var(--color-white);
}

.card:hover .links {
    visibility: visible;
}

.tag {
    background: var(--color-white);
    color: var(--color-gray);
    padding: 5px;
    /* border: 1px solid var(--color-gray); */
}

.tag-front::before {
    content: ' ● ';
    color: var(--color-blue);
}

.tag-back::after {
    content: ' ●';
    color: var(--color-pink);
}


.bg-blue {
    background: var(--color-blue);
}

.bg-pink {
    background: var(--color-pink);
}

.bg-grey {
    background: var(--color-light-gray);
}

@media screen and (max-width: 600px) {
    .links {
        visibility: visible;
    }
}


.parent {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bg {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 40%;
    width: 50%;
    height: 100vh;
    background-image: url(./img/me3.png);
    background-position: 20% 75%;
    background-repeat: no-repeat;
    background-size: cover;
    /* z-index: -3; */
}

.about {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 70px;
}

.list {
    list-style: none;
    font-weight: 300;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
    z-index: 5;
}

.list a:hover {
    color: var(--color-blue);
}

.scrollPrompt {
    opacity: 0.5;
    animation: prigskok 7s infinite;
    position: relative;
}

@keyframes prigskok {
    20% {
        top: 0
    }

    25% {
        top: -10px;
    }

    30% {
        top: 5px;
    }

    35% {
        top: -7px;
    }

    40% {
        top: 3px;
    }

    45% {
        top: 0;
    }
}

.title {
    font-size: 45px;
    line-height: 1;
    margin-bottom: 20px;
}

.textAccent {
    letter-spacing: 0.2em;
    color: var(--color-blue);
    transition: color 0.3s ease;
}

.textAccent:hover {
    color: var(--color-pink);
}

.aboutText {
    line-height: 1.5;
    text-align: justify;
    font-size: 24px;
}

@media screen and (max-width: 600px) {
    .bg {
        display: none;
    }

    .scrollPrompt {
        visibility: hidden;
    }
}
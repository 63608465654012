.parent {
    flex-grow: 1;
    display: flex;
    gap: 40px;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 40px;
    width: 300px;
    max-width: 50%;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 350px;
}

.field {
    display: flex;
    flex-flow: column-reverse;
    gap: 6px;
}


label,
.input {
    font-size: 14px;
    transition: all 0.2s;
    touch-action: manipulation;
}

.input {
    border: 0;
    color: var(--color-gray);
    border-bottom: 1px solid var(--color-medium-gray);
    font-family: inherit;
    -webkit-appearance: none;
    border-radius: 0;
    cursor: text;
    padding: 8px;
    background-color: var(--color-light-gray);
}


textarea.input {
    height: 100px;
    resize: none;
}

.input:focus {
    outline: 0;
    border-bottom: 1px solid var(--color-blue);
}

.input:placeholder-shown+label {
    cursor: text;
    text-align: left;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(8px, 30px) scale(1);
    color: var(--color-medium-gray);
}

::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
}

.input:focus::-webkit-input-placeholder {
    opacity: 1;
}

.input:not(:placeholder-shown)+label,
.input:focus+label {
    transform-origin: left bottom;
    transform: translate(0) scale(0.8);
    color: var(--color-blue);
    cursor: pointer;
}

.input:not(:focus)+label {
    color: var(--color-medium-gray);
}

.jc-center {
    justify-content: center;
}

.btn {
    border: 0;
    padding: 8px;
    background: var(--color-blue);
    font-family: inherit;
    color: white;
    transition: background-color 0.2s;
}

.btn:hover {
    background: var(--color-pink);
}

.btn:active {
    background: var(--color-pink);
}

.btn:focus {
    outline: none;
    box-shadow: 0 0 5px rgb(91, 91, 91);
    /* background:rgb(255, 1, 192); */
}

@media screen and (max-width: 800px) {
    .title {
        font-size: 30px;
        width: 200px;
    }
}


@media screen and (max-width: 600px) {
    .parent {
        flex-direction: column;
        align-items: center;
    }

    .title {
        font-size: 40px;
        text-align: center;
        max-width: none;
        width: auto;
    }
}
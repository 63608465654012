.footer {
    padding: 60px;
    color: var(--color-gray);
    background-color: var(--color-light-gray);
    display: flex;
    gap: 20px;
    justify-content: space-between;
    /* align-items: center; */
}

.left {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 200px;
}

.left p {
    font-size: 14px;
}

.textAccent {
    letter-spacing: 0.315em;
    color: var(--color-blue);
    transition: color 0.3s ease;
}

.list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex-wrap: wrap;
    font-size: 14px;
}

.list a:hover {
    color: var(--color-blue);
}


@media screen and (max-width: 800px) {
    .footer {
        padding: 30px;
    }
}


@media screen and (max-width: 600px) {
    .footer {
        padding: 15px;
    }
}